$sidebar-bg: #10131a;
$dark-blue-hover: #00619E;
.border-tb-dark { border-color: #cfcfcf !important;}
.logincard { background-color: #eff0f2;}
#login-row #login-box {
  border: 1px solid #e2e3e5;
  border-top: transparent;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.aside.aside-dark {background-color: $sidebar-bg;}  
.form-control, .form-select, .input-group-text {font-weight: normal; padding-top: 7px; padding-bottom: 7px;} 
.required-input .form-select, .required-input .form-control { border: 1px solid $primary;}
table.table {
  td { word-break: break-all;
    & i { color: $white;}
    & a.btn.btn-icon.btn-bg-primary {
      &:hover {background-color: $dark-blue-hover;}
    }
  }
} 
.Date-Range { position: relative;
  & .input-group-btn {
   position: absolute; right: -5px; top: -6px; left: auto; 
   & i.bi {font-size: 18px;}
  }
}
.search-all {
 & .input-group-btn {right: -20px;}
}

           







